import React, { FunctionComponent, useMemo, useState, useEffect } from "react";
import { AiFillStar } from "react-icons/ai";
import ReactTooltip from "react-tooltip";
import items from "../../../static/items.json";
const goldicon = require("../../../static/media/misc/gold.png");

interface CompositionChampion {
  name: string;
  items: string[];
  cost: number;
  traits: string[];
}

interface CompositionChampionProps {
  compositionId: string;
  compositionChampion: CompositionChampion;
  championTier: number;
}

const CompositionChampionComponent: FunctionComponent<
  CompositionChampionProps
> = ({
  compositionId,
  compositionChampion,
  championTier,
}: CompositionChampionProps) => {
  const getItemInformation = (itemId: string) => {
    return items.items.find((element) => {
      return element.apiName === itemId;
    });
  };

  const getItemTooltip = (itemId: string) => {
    const itemInformation = getItemInformation(itemId);
    console.log("id "+itemId + "  Info: "+ itemInformation);
    if (itemInformation?.composition.length === 0) {
      return itemInformation?.name + ";";
    }

    console.log("Item Information: "+ itemInformation?.composition[0] +"  "+ itemInformation?.composition[1]);
    return (
      itemInformation?.name +
      ";" +
      itemInformation?.composition[0] +
      ";" +
      itemInformation?.composition[1]
    );
    //  (
    //   <div>
    //     <h3 style={{ margin: "0", padding: "0" }}>{itemId}</h3>
    //   </div>
    // );
    // const itemInformation = getItemInformation(itemId);
    // console.log(itemInformation);
    // if (itemInformation) {
    //   const tooltip = [];
    //   tooltip.push(
    //     <h3 style={{ margin: "0", padding: "0" }}>
    //       {itemInformation.name}
    //     </h3>
    //   );
    //   tooltip.push(
    //     <div className="dots">
    //       <div>&#x2022;</div>
    //       <div>&#x2022;</div>
    //       <div>&#x2022;</div>
    //     </div>
    //   );
    //   for (let index = 0; index < itemInformation.from.length; index++) {
    //     const element = itemInformation.from[index];
    //     tooltip.push(
    //       <h3 style={{ margin: "0", padding: "0" }}>
    //         {element}
    //       </h3>
    //     );
    //   }
    // }
  };

  const itemImages = useMemo(() => {
    var itemComponents = [];
    if (!compositionChampion.items) {
      return;
    }
    for (var i = 0; i < compositionChampion.items.length; i++) {
      var originalId = compositionChampion.items[i];
      var id = originalId;
      if (Number(compositionChampion.items[i]) < 10) {
        id = `0${id}`;
      }
      id = id.replace("TFT_Item_","").replace("TFT9_Item_","").replace("TFT10_Item_","").replace("TFT11_Item_","").replace("Item","");
      const itemImgUrl = `https://tftracker.shosenfeld.com/images/items/${id}.png`;
      itemComponents.push(
        <img
          className="Item-Component"
          data-tip={getItemTooltip(originalId)}
          data-for={`itemTooltip`}
          src={itemImgUrl}
          alt={compositionChampion.items[i]}
        />
      );
    }
    return itemComponents;
  }, [compositionChampion]);

  const tierStars = useMemo(() => {
    var starComponents = [];
    for (var i = 0; i < championTier; i++) {
      starComponents.push(<AiFillStar className="tier-star" />);
    }
    return starComponents;
  }, [championTier]);

  const [width, setWidth] = useState<number>(window.innerWidth);
  const [widthString, setWidthString] = useState<string>("large");

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    if (window.innerWidth > 1499) {
      setWidthString("large");
    } else {
      setWidthString("small");
    }
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const cleanName = compositionChampion.name
    .replaceAll("Set13_", "")
    .replaceAll("Set13b_", "")
    .replaceAll("TFT13_", "")
    .replaceAll("tft13_", "")
    .replaceAll("TFT13b_", "");

  var displayName = cleanName;
  //Idas = DragonGold
  //Shi Oh Yu = DragonGreen
  //Daeja = DragonBlue
  //Syfen = DragonPurple

  switch (displayName) {
    case "elise":
      displayName = "Elise";
      break;
    case "Red":
      displayName = "Violet";
      break;
    case "Blue":
      displayName = "Powder";
      break;
    case "Prime":
      displayName = "Vander";
      break;
    case "Lieutenant":
      displayName = "Sevika";
      break;
    case "FlyGuy":
      displayName = "Scar";
      break;
    case "Fish":
      displayName = "Steb";
      break;
    case "Beardy":
      displayName = "Loris";
      break;
    case "swain":
      displayName = "Swain";
      break;
    case "Gremblin":
      displayName = "Smeech";
      break;
    case "Chainsaw":
      displayName = "Renni";
      break;
    case "Shooter":
      displayName = "Maddie";
      break;
  }

  const tooltip = [];
  tooltip.push(<h3 style={{ margin: "0", padding: "0" }}>{displayName}</h3>);
  tooltip.push(
    <div
      style={{
        flexFlow: "row",
        margin: "0",
        padding: "0",
        display: "inline-flex",
        alignItems: "center",
      }}
    >
      <p
        style={{
          height: "100%",
          fontSize: "larger",
          margin: "0",
        }}
      >
        {compositionChampion.cost}
      </p>
      <img
        alt="gold"
        style={{ marginLeft: ".5em", height: "100%" }}
        src={goldicon}
      />
    </div>
  );
  tooltip.push(
    <div className="dots">
      <div>&#x2022;</div>
      <div>&#x2022;</div>
      <div>&#x2022;</div>
    </div>
  );
  if (compositionChampion.traits) {
    for (var i = 0; i < compositionChampion.traits.length; i++) {
      tooltip.push(
        <p style={{ margin: ".2em" }}>{compositionChampion.traits[i]}</p>
      );
    }
  }
  const unitImg = `https://tftracker.shosenfeld.com/images/champions/${cleanName.toLowerCase()}.png`;

  const showItems = itemImages ? itemImages.length > 0 : false;

  return (
    <div
      className={
        width > 800 ? "Champion-Component" : "Champion-Component-Small"
      }
    >
      <div className="champion-box">
        {width > 1800 && (
          <div className="Champion-Name-Large">{displayName}</div>
        )}
        {width <= 1800 && width > 800 && (
          <div className="Champion-Name-Medium">{displayName}</div>
        )}
        {width <= 800 && (
          <div className="Champion-Name-Small">{displayName}</div>
        )}

        <div className="tier-star-box">{tierStars}</div>
      </div>
      <div className="Champion-Item-Container">
        <img
          src={unitImg}
          alt={displayName}
          className={
            "champion-image-" +
            widthString +
            " border-color-" +
            compositionChampion.cost
          }
          data-tip
          data-for={`championToolTip-${displayName}-${compositionId}`}
        />
        <ReactTooltip
          id={`championToolTip-${displayName}-${compositionId}`}
          place="top"
          effect="solid"
        >
          {tooltip}
        </ReactTooltip>
        <div className="Champion-Items">{showItems && itemImages}</div>
      </div>
    </div>
  );
};

export default CompositionChampionComponent;
