import React, { FunctionComponent, useMemo, useState, useEffect } from "react";
import { PlacementAmountsByPlacement } from "../../model/model";
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import {
    useQuery,
  } from 'react-query'
  
    ChartJS.register(
      CategoryScale,
      LinearScale,
      BarElement,
      Title,
      Tooltip,
      Legend
    );


interface PlacementProps {
    placements: PlacementAmountsByPlacement;
  }

  

const PlacementGraph: FunctionComponent<PlacementProps> = ({
    placements,
  }: PlacementProps) => {

    const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
    
    const options = {
        animation: {
            duration: 0
        },
    responsive: true,
    scales: {
        x: {
            stacked: true,
            ticks:{
                color: 'white'
            },
            grid: {
                display: false
            },
          },
        y: {
            stacked: true,
            grid: {
                display: false
            },
            ticks: {
                display: false,
            }
        }
      },
    plugins: {tooltip: {
        enabled: false
             },
      legend: {
          display: false
      },
      title: {
        display: false,
      },
    },
  };

  const chartData = useMemo(()=> {
    
    var labels : string[];
    labels = [];

    Object.keys(placements).forEach(element => {
        labels.push(`${element}.`)
    });

    const placementChartData = Object.values(placements)
    var winPlacementChartData = [];
    var lossPlacementChartData = [];
    for (let index = 0; index < placementChartData.length; index++) {
        if(index<4){
        winPlacementChartData.push(placementChartData[index]);
        lossPlacementChartData.push(null);
        }else{
            lossPlacementChartData.push(placementChartData[index]);
        }
    }
  
    return {
      labels,
      datasets: [
        {
          label: 'Top 4',
          data: winPlacementChartData,
          backgroundColor: 'rgba(9, 172, 9, 0.9)',
        },
        
        {
            label: 'Bottom 4',
            data: lossPlacementChartData,
            backgroundColor:  'rgba(202, 13, 13, 0.9)',
          },
      ],
    }
  }, [placements])

    const showChart = chartData.labels.length > 0;

    return (
    <div>
        <div className="Placement-Box">
            {showChart && <Bar options={options} data={chartData}></Bar>}
            </div>
    </div>
  )
}

export default PlacementGraph