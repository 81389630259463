import React from 'react'
import "./Header.css"

const Header = () => {
  return (
    <div>
        <div className='Header'>
            <img className='Header-logo' src={require('./img/Furyhorn_Black.jpg')} alt='logo'></img>
            <div className='Logo-font'>TFTracker</div>
        </div>
    </div>
    )
}

export default Header