import React, { FunctionComponent, useMemo } from "react";

interface StarterItemsProps {
  starterItems: string[];
}

const StarterItems: FunctionComponent<StarterItemsProps> = ({
  starterItems,
}: StarterItemsProps) => {
  const itemImages = useMemo(() => {
    var itemImg = [];
    for (var i = 0; i < starterItems.length; i++) {
      var id = starterItems[i].toString();
      if (Number(starterItems[i]) < 10) {
        id = `0${id}`;
      }
      const itemImgUrl = `https://tftracker.shosenfeld.com/images/items/${id}.png`;

      itemImg.push(
        <img
          src={itemImgUrl}
          alt={`Item_${id}`}
          className="StarterItem-Image"
        />
      );
    }
    return itemImg;
  }, [starterItems]);

  const showItems = itemImages.length > 0;

  return (
    <div>
      <div className="StarterItems-Text">Starter Items</div>
      <div className="Display-flex">{showItems && itemImages}</div>
    </div>
  );
};

export default StarterItems;
