import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import "./App.css";
import Body from "./components/body/Body";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  },
});

function App() {
  return (
    <div>
      <div className="App">
        <QueryClientProvider client={queryClient}>
          <Header></Header>
          <Body></Body>
          <Footer></Footer>
        </QueryClientProvider>
      </div>
    </div>
  );
}

export default App;
