import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import "./Body.css";
import Card from "@mui/material/Card";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import {
  Composition as CompositionModel,
  PlacementAmountsByPlacement,
} from "../../model/model";
import CompositionComponent from "./CompositionComponent";
import { useQuery } from "react-query";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface LeagueCompositionsProps {
  league: number;
}

const LeagueCompositions: FunctionComponent<LeagueCompositionsProps> = ({
  league,
}: LeagueCompositionsProps) => {
  const options = {
    indexAxis: "y" as const,
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: "Most played comps in " + league,
      },
    },
  };

  // const {data : rawCompositionData, isFetching, isFetched} = useQuery('compositions', ()=> fetch('https://tftracker.shosenfeld.com/compositions?tier='+league+'&amountOfCompositions=10&amountOfChampionsPerComposition=8&amountOfItems=6').then(response => response.json() as Promise<CompositionModel[]>))
  const {
    data: rawCompositionData,
    isFetching,
    isFetched,
  } = useQuery("compositions", () =>
    fetch(
      "https://tftracker.shosenfeld.com/api/combined-compositions?amountOfCompositions=30&amountOfChampionsPerComposition=8&amountOfItems=6"
    ).then((response) => response.json() as Promise<CompositionModel[]>)
  );

  useEffect(() => {
     console.log(rawCompositionData)
  }, [rawCompositionData])

  const [selectedOption, setSelectedOption] = useState<String>("winrate");

  // This function is triggered when the select changes
  const selectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setSelectedOption(value);
  };

  const getRelativePositivePlacements = (
    placements: PlacementAmountsByPlacement
  ) => {
    let topFour = 0;
    let amount = 0;
    for (let index = 1; index <= 8; index++) {
      if (index <= 4) {
        topFour += placements[index.toString()] * (5 - index);
      }
      amount += placements[index.toString()];
    }
    return topFour / amount;
  };

  const winningCompositionData = useMemo(() => {
    let result :CompositionModel[] = [];

    if (rawCompositionData) {
      rawCompositionData.forEach(val => result.push(Object.assign({}, val)));
      // result = rawCompositionData;
      if(result){
      result.sort(
        (a, b) =>
          getRelativePositivePlacements(b.PlacementAmountsByPlacement) -
          getRelativePositivePlacements(a.PlacementAmountsByPlacement)
      );}
    }
    return result;
  }, [rawCompositionData]);

  const compositionData = useMemo(
    () => {
    if(selectedOption && selectedOption === "popularity"){
      
      
      return rawCompositionData || [];
    }
    else{
      
      return winningCompositionData || [];
    }
    },
    [rawCompositionData, selectedOption, winningCompositionData]
  );

  const chartData = useMemo(() => {
    const labels = compositionData.map((element) => element.Name);
    const compositionChartData = compositionData.map(
      (element) => element.Amount
    );

    return {
      labels,
      datasets: [
        {
          label: "Composition 1",
          data: compositionChartData,
          backgroundColor: "rgba(220, 221, 234, 0.8)",
        },
      ],
    };
  }, [compositionData]);

  const compositionRows = useMemo(
    () =>
      compositionData.map((comp) => (
        <CompositionComponent composition={comp} key={comp.Name} />
      )),
    [compositionData]
  );

  const showChart = chartData.labels.length > 0;

  return (
    <div className="Background-Dark Card">
      <div className="sort-div">
        <label className="general-text">Sort by </label>
        <select className="select" onChange={selectChange}>
          <option selected value="winrate">
            Winrate
          </option>
          <option value="popularity">Popularity</option>
        </select>
      </div>
      {/* <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7599691694055264"
     crossOrigin="anonymous"></script>

<ins className="adsbygoogle"
     style={{display:'block'}}
     data-ad-client="ca-pub-7599691694055264"
     data-ad-slot="3048874867"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>
<script>
     (adsbygoogle = window.adsbygoogle || []).push({});
</script> */}
      <div className="Card-grid">
        <div>{isFetched && compositionRows}</div>
      </div>
      <div>
        {/* {showChart && <Bar options={options} data={chartData} className='Height-fix'></Bar>} */}
      </div>
    </div>
  );
};

export default LeagueCompositions;
