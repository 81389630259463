import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { Composition } from "../../model/model";
import CompositionChampionComponent from "./CompositionChampionComponent";
import PlacementGraph from "./PlacementGraph";
import StarterItems from "./StarterItems";
import TraitComponent from "./TraitComponent";
import ReactTooltip from "react-tooltip";

interface CompositionProps {
  composition: Composition;
}

const decReg = /\d+/;
const underlineReg = /_+/;

const CompositionComponent: FunctionComponent<CompositionProps> = ({
  composition,
}: CompositionProps) => {
  const compositionUnits = useMemo(() => {
    return Object.keys(composition.UnitAmountByUnitId).map((name) => {
      return {
        name,
        items: composition.ItemAmountByItemIdByUnitId[name]
          ? Object.keys(composition.ItemAmountByItemIdByUnitId[name])
          : [],
        cost: composition.UnitDataByUnitId[name].cost,
        traits: composition.UnitDataByUnitId[name].traits,
      };
    });
  }, [composition]);

  // const augments = useMemo(() => {
  //   var augs = [];
  //   for (var i = 0; i < composition.Augments.length; i++) {
  //     augs.push(
  //       <div className="augments-text">
  //         {composition.Augments[i]
  //           .replace(decReg, "")
  //           .replace(decReg, "")
  //           .replace("TFT_Augment_", "")
  //           .replace("TFT_", "")
  //           .replace(underlineReg, "")
  //           .replace(underlineReg, "")
  //           .replace("Augment_", "")
  //           .replace("Augment", "")
  //           .replace(/([A-Z])/g, " $1")
  //           .trim()}
  //       </div>
  //     );
  //   }
  //   return augs;
  // }, [composition]);

  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const unitComps = useMemo(() => {
    var tmpComps = compositionUnits;
    tmpComps = tmpComps.sort((a, b) => a.cost - b.cost);
    var unitComponents = [];
    for (var i = 0; i < tmpComps.length; i++) {
      unitComponents.push(
        <CompositionChampionComponent
          compositionId={composition.Name}
          compositionChampion={tmpComps[i]}
          championTier={composition.UnitTierByUnitId[tmpComps[i].name]}
          key={tmpComps[i].name}
        />
      );
    }
    return unitComponents;
  }, [compositionUnits]);

  const showUnits = compositionUnits.length > 0;
  const cleanName = composition.Name.replaceAll("Set13_", "")
    .replaceAll("Set13b_", "")
    .replaceAll("TFT13_", "")
    .replaceAll("TFT13b_", "")
    .replaceAll("Cabal", "Black Rose")
    .replaceAll("Pugilist", "Pit Fighter")
    .replaceAll("Infused", "Dominator")
    .replaceAll("Squad", "Enforcer")
    .replaceAll("Invoker", "Visionary")
    .replaceAll("Warband", "Conqueror")
    .replaceAll("Ambassador", "Emissary")
    .replaceAll("Hoverboard", "Firelight")
    .replaceAll("Martialist", "Artillerist")
    .replaceAll("Crime", "Chem-Baron")
    .replaceAll("Titan", "Sentinel");

    

  return (
    <div className="Composition-Box">
      <div className="Composition-Name">{cleanName}</div>
      <div className="Display-Flex">
        {width > 1200 && (
          <div className="Display-Wide">
            <div className="Width-20">
              <StarterItems
                starterItems={composition.StarterItems}
              ></StarterItems>
              <div className="dots">
                <div>&#x2022;</div>
                <div>&#x2022;</div>
                <div>&#x2022;</div>
              </div>
              <TraitComponent
                traits={composition.CompositionTraits}
              ></TraitComponent>
            </div>
            <div className="Width-60 Display-Flex">
              {showUnits && unitComps}
            </div>
            <div className="Width-20">
              {/* <div className="augments">{augments}</div> */}
              <div className="dots">
                <div>&#x2022;</div>
                <div>&#x2022;</div>
                <div>&#x2022;</div>
              </div>
              <PlacementGraph
                placements={composition.PlacementAmountsByPlacement}
              ></PlacementGraph>
            </div>
          </div>
        )}
      </div>
      {width <= 1200 && width > 800 && (
        <div>
          <div className="Display-Flex">{showUnits && unitComps}</div>
          <div className="line"></div>
          <div className="Display-Flex">
            <div className="Width-35">
              <StarterItems
                starterItems={composition.StarterItems}
              ></StarterItems>
              <div className="dots">
                <div>&#x2022;</div>
                <div>&#x2022;</div>
                <div>&#x2022;</div>
              </div>
              <TraitComponent
                traits={composition.CompositionTraits}
              ></TraitComponent>
            </div>
            <div className="Width-35 Align-Right">
              {/* {augments} */}
              <div className="dots">
                <div>&#x2022;</div>
                <div>&#x2022;</div>
                <div>&#x2022;</div>
              </div>
              <PlacementGraph
                placements={composition.PlacementAmountsByPlacement}
              ></PlacementGraph>
            </div>
          </div>
        </div>
      )}
      {width <= 800 && (
        <div>
          <div className="Display-Flex">
            {showUnits && unitComps.slice(0, 4)}
          </div>
          <div className="Display-Flex">
            {showUnits && unitComps.slice(4, 8)}
          </div>
          <div className="line"></div>
          <div className="Display-Flex">
            <div className="Width-50">
              <StarterItems
                starterItems={composition.StarterItems}
              ></StarterItems>
              <div className="dots">
                <div>&#x2022;</div>
                <div>&#x2022;</div>
                <div>&#x2022;</div>
              </div>
              <TraitComponent
                traits={composition.CompositionTraits}
              ></TraitComponent>
            </div>
            <div className="Width-50 Align-Right">
              {/* {augments} */}
              <div className="dots">
                <div>&#x2022;</div>
                <div>&#x2022;</div>
                <div>&#x2022;</div>
              </div>
              <PlacementGraph
                placements={composition.PlacementAmountsByPlacement}
              ></PlacementGraph>
            </div>
          </div>
        </div>
      )}

      <ReactTooltip id="global" place="bottom" type="dark" effect="solid" />
      <ReactTooltip
        id="itemTooltip"
        place="bottom"
        type="dark"
        effect="solid"
        getContent={(dataTip) => (
          <div>
            <div className="text-larger">{dataTip?.split(";")[0]}</div>
            <div className="dots">
              <div>&#x2022;</div>
              <div>&#x2022;</div>
              <div>&#x2022;</div>
            </div>
            <div className="flexbox">
              {dataTip?.split(";").length === 3 ? (
                <>
                  <img
                    className="Item-Tooltip"
                    src={`https://tftracker.shosenfeld.com/images/items/${
                      dataTip?.split(";")[1]
                    }.png`}
                    alt={`Item`}
                  ></img>
                  <span>+</span>
                  <img
                    className="Item-Tooltip"
                    src={`https://tftracker.shosenfeld.com/images/items/${
                      dataTip?.split(";")[2]
                    }.png`}
                    alt={`Item`}
                  ></img>
                </>
              ) : (
                <>
                <span>Only available through augments!</span></>
              )}
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default CompositionComponent;
