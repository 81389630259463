import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <p className="footer-text">
        TFTracker isn't endorsed by Riot Games and doesn't reflect the views or opinions of Riot Games or anyone officially 
        involved in producing or managing Riot Games properties. Riot Games, and all associated
        properties are trademarks or registered trademarks of Riot Games, Inc.
      </p>
    </div>
  );
};

export default Footer;
