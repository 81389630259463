import React, { FunctionComponent, useEffect, useMemo } from "react";
import { CompositionTrait } from "../../model/model";
import ReactTooltip from "react-tooltip";

interface TraitComponentProps {
  traits: CompositionTrait[];
}

const TraitComponent: FunctionComponent<TraitComponentProps> = ({
  traits,
}: TraitComponentProps) => {

  const getTraitTooltip = (trait : CompositionTrait) => {
    return trait.TraitId;    
  }

  const traitElements = useMemo(() => {
    var traitEle = [];
    for (var i = 0; i < traits.length; i++) {
      var id = traits[i].TraitId;

      const traitImgUrl = `https://tftracker.shosenfeld.com/images/traits/${traits[i].TraitId.toLowerCase().replaceAll(".","").replaceAll(":","").replaceAll(" ","").replaceAll("/","")}.png`;

      traitEle.push(
        <div style={{ width: "32px", overflow: "hidden" }}>
          <img data-tip={getTraitTooltip(traits[i])} data-for={`global`} src={traitImgUrl} alt={`${id}`} className="Trait-Image" />
          <div className="general-text">{traits[i].UnitAmount}</div>
        </div>
      );
    }
    return traitEle;
  }, [traits]);

  const showTraits = traitElements.length > 0;

  return (
    <div>
      <div className="Traits-Text">Active Traits</div>
      <div className="trait-flexbox">{showTraits && traitElements}</div>
    </div>
  );
};

export default TraitComponent;
