import LeagueCompositions from "./LeagueCompositions";
import "./Body.css";

const Body = () => {
  return (
    <div className="Body">
      <LeagueCompositions league={0}></LeagueCompositions>
      {/* <LeagueCompositions league={1}></LeagueCompositions>
        <LeagueCompositions league={2}></LeagueCompositions>
        <LeagueCompositions league={3}></LeagueCompositions>
        <LeagueCompositions league={4}></LeagueCompositions> */}
    </div>
  );
};

export default Body;
